.rdp {
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: #3f74d3; /* Accent color for the background of selected days. */
  --rdp-background-color: #ebf1fa; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #2d65c9; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #3f74d3; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}

.rdp-day {
  color: #272830;
}

.rdp-day_today:not(.rdp-day_outside):not(.rdp-day_selected) {
  border-radius: 20px;
  border-color: #dee3ec;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: var(--rdp-selected-color);
  opacity: 1;
  background-color: var(--rdp-accent-color);
}
